body{
    background-color: #f4f4f4 !important;
    text-align: justify !important;
}
.header-transparent #main-nav > li > a {
    color: #131212;
}
.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 0px;
}
#main-nav{
    margin-right: 30px;
}
.author_right::after{
    background:transparent !important;
}
.counter-infodiv{
    border-right: 1px solid #d2d2d2;
    height: 176px;
}
/* .counter_top_img{
    max-height: 208px !important;
    min-height: 208px !important;

    overflow: hidden;
} */

.menu_img img{
    min-height: 208px;
}
.indic_div{
    padding: 0px 15px 0px 15px;
    border:'1px solid #48368e !important';
    background:'#fff !important';
    padding:'0px 0px 3px 5px !important';
    box-shadow:'0.5rem 1rem rgba(0,0,0,.15)!important'
}

.text_justify{
    text-align: justify !important;
}

.footer_main_div{
    padding-top: 25px;
    padding-bottom: 15px;
}

.footer_heading{
    border-bottom: none !important;
    margin-bottom: 0px !important;
}
/* #Footer */
.footer-main {
    background-color: #737070 !important ;
    /* background-image: url("../../images/background/footer-bg.jpg");
    background-size: cover; */
  } 
  .tab2_tab{
      padding: 30px 30px 0px 90px !important;
  }
/* Smrithi */
.detail_section h2 ,.detail_section h3 {
    color: black;
    font-weight: 400;
}
.detail_section .child_section .card{
    background: whitesmoke;
    padding: 1.5rem;
    border-radius: 10px;
}

.detail_section .child_section .card form input{
    background: white;
}

.detail_section .child_section form .swin-btn {
    min-width: 50px;
    min-height: 20px;
    padding: 10px 35px;
    background-color: gray;
    border: 1px solid gray;
    text-transform: uppercase;
    border-radius: 10px;
    display: inline-block;
    position: relative;
    color: #fff;
}
.detail_section .child_section form .swin-btn:hover {
    min-width: 50px;
    min-height: 20px;
    padding: 10px 35px;
    background-color:black;
    border: 1px solid black;
    text-transform: uppercase;
    border-radius: 10px;
    display: inline-block;
    position: relative;
    color: #fff;
}

.sub_cat_heading{
    text-align: center;
    background: gray;
    border-radius: 10px;
    padding: 10px;
    color: white;
    font-size: 30px;
}

.swin-sc-title.style-2 .title {padding-top: 20px;}
.child_section li , .detail_section p{
    color: black;font-size: 16px;
}

.sustitle {    font-size: 20px;
    color: #000;}

.row_section p{
    font-size: 20px;
}

.p-top {
    padding-top: 35px;
}

.p-btm{
    padding-bottom: 35px;
}

.m-top{
    margin-top: 20px;
}

.detail_section hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 5px solid #d1caca;
}

.list_section ul{
        display:flex;
        justify-content: space-between;
        list-style-type: none;
}

.list_section li{
    text-align: center;
    padding: 0px 2px 0px 0px;
    font-size: 15px;

}

.list_section li p{
    width: 10em;
}

header.header-fixed .header-main #main-nav > li > a {
    line-height: 80px; }

    

@media only screen and (min-width: 968px) {
    .header-main .header-logo {
        width: 180px !important;
        min-height: 100px!important;
        float: left;
        position: relative;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    header.header-fixed .header-main {
        height: 100px!important;
    }
    
 
  }

  @media only screen and (max-width: 720px) {
    
        /* .diningmenu{
            display: grid !important;
            align-items: center;
        } */
        .row-center{
            display: grid !important;
           align-items: center;
       }
    .list_section ul{
        display:inline-block;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        list-style-type: none;
    }
    .detail_section .child_section iframe{
        width: inherit;
    }
    .col-sm-12:not(:last-child) {
        margin-bottom: 5px; 
    }
    .detail_section .child_section .card {
        background: whitesmoke;
        padding: 0.1rem;
        border-radius: 10px;
    }
    .related_doc{
        padding: 10px;
    }
  }

 
  .drop-menu{
    font-size: 16px !important;
    padding: 1px 1px 1px 1px;
  }
  .drop-menu:hover{
      background-color: rgba(51, 51, 51, 0.103);
  }
  .dropdown-menu>li>a {
    display: block;
    padding: 3px 5px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    font-size: 17px !important;
}
.my_table{
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
    padding: 10px;
}
td, th {
    padding: 10px;
}

    @media only screen and (min-width: 720px) and (max-width:1040px) {
        
        .counter_top_img{
            height: auto !important;
            overflow:scroll;
        }

        .list_section li p{
            width: 6em;
        }
        .detail_section .child_section iframe{
            width: inherit;
        }

        .lobby_tab{
            padding-inline:100px !important;
        
          }
          .conf_box_hide{
              display: none;
          }
          .site_logo{
              height: 60px !important;
              width: 130px !important;
          }
    
  }


  @media only screen and (min-width: 320px) and (max-width:720px) {
    .list_section li p{
        width: 17em;
    }
    .detail_section .child_section iframe{
        width: inherit;
    }
       .mobile_hide{
        display: none;
        padding: 0px !important;
    }

}
/* Smrithi */

/* rafik css start */
.diningmenu{
    background-color: #fff;
    /* padding: 0 !important; */
    /* display: flex;
    align-items: center; */
}
.row-center{
     display: flex;
    align-items: center;
}
.ft-fixed-area .reservation-wrap {
    border: 0px solid #616161 !important;
    padding: 0 i !important;
    position: relative;
}
.ft-fixed-area .reservation-wrap::before {
    /* border: 1px solid #616161; */
    padding: 10px 25px 25px;
    position: relative;
    display: none;
}
.ft-fixed-area .reservation-wrap::after {
    /* border: 1px solid #616161; */
    padding: 10px 25px 25px;
    position: relative;
    display: none;
}
.ft-fixed-area .reservation-wrap .res-date .res-date-text {
    display: table-cell;
    color: #000;
    width: 120px;
    font-weight: 200 !important;
}
.ft-fixed-area .reservation-wrap .res-time{
    font-weight: 200 !important;
}

/* Sunil */
.sub-padding {padding-top: 35px;
    padding-bottom: 15px;}
.sub_heading {text-align:center;font-weight: 300;color:#000;}

.top_heading{
    color: #000;
}
.top_heading2{
    color: #1155cc;
    /* font-size: 23px; */
    font-weight: bold;
    text-decoration: underline;
}
.top_heading2:hover{
    color: #1155cc !important;
    /* font-size: 23px; */
    text-decoration: underline; 
    font-weight: bold;
}
.top_heading_margin{
    margin: 0px 0px 20px 0px;
}

.table_heading {font-weight: 500;
    font-size: 20px;
    line-height: 1.75rem;
    color: #000;}
.conference_view_center {margin:20px 0px;} 
.col-md-3:nth-child(-n+3) .item {border-right: 1px solid #d2d2d2;}   

.table_content {    
    color: #000;
    font-size: 1.6rem;margin-bottom: 25px;}

 .fa {
    margin-top: 22px;}   

#panel2,#panel3 {display: none;}    

.category_list{
    background-color: #fff;
    text-align: center;
}
.category_name{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.price{
    color: #000 !important;
}
.menu-item{
    background-color: #fff;
    padding: 20px;
    margin: auto;
    border-left: double;
}
.section-padding{
    background: #f4f4f4;
    padding-top: 0px !important;
}
.list_one, .list_two,.list_three,.list_four,.list_five{
    cursor: pointer;
}
.list_one_list, .list_two_list,.list_three_list,.list_four_list,.list_five_list{
    position:absolute;
    background-color: #fff;
    display: none;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
    text-align: justify !important;   
    z-index: 99;
    padding: 5px 12px 5px 27px;
    
}
.align{
    min-width: 250px;
}
.align li{
    text-align: justify !important;
    padding-left: 0px;
}
.list_one:hover .list_one_list{
    display: block;
    
}
.list_two:hover .list_two_list{
    display: block;
}
.list_three:hover .list_three_list{
    display: block;
    margin-right: 40px !important;
}
.list_four:hover .list_four_list{
    display: block;
}
.list_five:hover .list_five_list{
    display: block;
}
.chart {width:800px;}
.MuiTableCell-root{
    font-size: 13px !important;
    padding: 4px !important;
}
.view-more-button{
    cursor: pointer;
}

.titlepdf {color:#000;
    padding-bottom: 0px;
    line-height: 0;}

 .swin-btn-abso {position: absolute;right:0px;margin-top:-55px;border: 1px solid #f44336;}  

 .download_icon {margin-top: 0px;color:#f44336;margin-left: 10px;}

 .space-btw {padding:30px 0px;border-bottom: solid 1px #e2e2e2;} 

 .bg-color {background-color:#fff;}

 @media screen and (max-width: 480px){
.swin-sc-title .title {
    font-size: 34px;}
    .space-btw {
        padding: 15px 15px;
        border-bottom: solid 1px #e2e2e2;
    }
    .swin-btn-abso {
        position: relative;
        left: 0px;
        margin-top: -55px;
        border: 1px solid #f44336;
        float: none;
    }
    .counter-info{
        margin-top: 0px !important;
    } 
    .m-top{
        margin-top: 0px;
    }
}

.modal-body{
    color: #000;
    font-weight: 600;
    
}
.modal-header .close{
    margin-top: -25px;
}
/* model css strat  */
#mask {
    position:absolute;
    left:0;
    top:0;
    z-index:9000;
    background-color:#000;
    display:none;
  } 
  .window{
      top: 70px !important;
  } 
  #boxes .window {
    position:absolute;
    padding: 0;
    left:0;
    top:0;
    width: 80% !important;
    height:auto;
    display:none;
    z-index:9999;
    padding:20px;
    border-radius: 15px;
    text-align: center;
  }
  #boxes #dialog {
    width:auto; 
    height: auto;
    /* height:600px;
    overflow: scroll; */
    /* padding:10px; */
    background-color:#ffffff;
    font-family: 'Segoe UI Light', sans-serif;
    font-size: 15pt;
  }
  .maintext{
      text-align: center;
    font-family: "Segoe UI", sans-serif;
    text-decoration: none;
  }
  
  #lorem{
      font-family: "Segoe UI", sans-serif;
      font-size: 12pt;
    text-align: left;
  }
  #popupfoot{
      font-family: "Segoe UI", sans-serif;
      font-size: 16pt;
    padding: 10px 20px;
  }
  #popupfoot a{
      text-decoration: none;
  }

  .popupoption:hover{
      background-color:#D1D1D1;
      color: green;
  }
  .popupoption2:hover{
      
      color: red;
  }
.model_heading{
    position: sticky;
}
.close{
    /* float: left !important; */
    
    color: #000 !important;
}
.model_img{
    width: 100%;
    
    background-repeat: no-repeat;
}
  /* model css end  */

.realtedoc {font-size: 16px;
    font-weight: 400;
    color: #795548;}


 .img-bottom {margin-bottom: 10px;}   

 .meal_box{
    /* margin-right: 5px; */
    text-align: center;
    border-right: 1px solid #d2d2d2;
}

.d-center{
    display:flex;
    justify-content:center !important
}

.meal_box:last-child {
    border-right: 1px solid #fff;
}

.maintitle {font-family: 'Merriweather Sans', sans-serif;font-size: 30px;font-weight: 500!important;}

.title {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 30px;
    text-transform: capitalize;
    color: #29272e;
    margin: 20px 0px 20px 0px; }

    .swin-sc-title {
        margin-bottom:0px;
      }   
.mt-4{
    margin-top: 20px;
}
.heading_logo{
    margin-right: 20px;
}


.tab-middle h2{
    margin-top: 20px;
    margin-bottom: 20px;
}
.media-heading{
    color: #000;
}
.fa-icon{
    margin-top: 0px !important;
}

/* carousel height */

._2ILZE {height:600px!important;}
.mb-3{
    margin-top:20px;
}
.tab_heading{
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 30px;
    text-transform: capitalize;
    color: #000;
}
.allergens_img{
    background-size: cover;
    width: 100%;
}
.MuiTable-root{
    margin-bottom: 20px !important;
}
.text-center{
    text-align: center;
}
.site_logo{
    width: 160px;
    height: 90px;
}
.lobby_tab{
    padding-inline:30%;
}
.dish_icon{
    width: 20px;
    height: 20px;
    margin-top: 5px;
}
.slider_top{
    margin-top: 40px !important;
}
.padding_set{
    padding: 0px !important;
}
@media only screen and (max-width: 600px) {
    .d-center{
        display:grid !important;
    }
    .drop-list{
        line-height:10px !important;
        padding:0px !important;
    }
    .mobile_hide{
        display: none;
        padding: 0px !important;
    }
    
    .site_logo{
        width: 120px;
        height: 55px;        
    }

    .bnr_img{
        height: 250px !important;
        width: 100% !important;
    }
    .bnr_img_30{
        height: 200px !important;
        width: 100% !important;
    }
    
    .window{
        left: 37px !important;
    }
    .indicator_div{
        margin: 20px 20px 20px 20px;
    }
    
    .section_padding{
        padding: 40px !important;
    }
    .meal_box{
        margin-right: 5px;
        text-align: center;
        border-right: none;
    }
    .counter-infodiv{
        border-right: none;
        height: 84px;
        padding-top: 10px;
    }
    .dish_icon{
        width: 15px;
        height: 15px;
    }
    .tab2_tab{
        padding: 10px !important;
    }
    .dish_name{
        font-size: 16px !important;
        color: #000;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }
    .header-main {
        height: 60px;
        box-shadow: 0 5px 25px rgb(0 0 0 / 10%);
    }
    .lobby_tab{
        padding-inline:50px;
    }
    ._2ILZE {height:300px!important;}
    header.header-fixed .header-main #main-nav > li > a {
    line-height: 25px !important; }
    .counter-info{
        margin-top: 0px !important;
    }
    .m-top{
        margin-top: 0px !important;
    }
    .p-btm {
        padding-bottom: 10px !important;
    }
    .p-top {
        padding-top: 15px !important;
    }

    #main-nav li .btn-open-dropdown {
        display: inline-block;
        position: absolute;
        right: 15px;
        top: 0;
        padding: 0 0px;
        line-height: 12px;
    }
    .meal_heading{
        color:#616f7d ;
        font-size: 20px !important;
        font-weight: 500;
    }
    .meal_content{
        font-size: 16px !important;
    }
    .meal_box{
        margin-top: 20px;
    }
    .chart{
        width: 100%;
    }
    .menu_img{
        display: none;
        margin-top: 10px;
    }

   
    


    
    .tabs-btn{
        margin-top: 20px;
        margin-left: 5px !important;
    }
    
    .conf_box{
        background: gainsboro;
        margin-top: 3px;
    }
    .dropdown-menu>li>a {
        display: block;
        padding: 3px 5px;
        clear: both;
        font-weight: 400;
        line-height: 1.42857143;
        color: #333;
        white-space: nowrap;
        font-size: 16px !important;
    }
   
  }
  .meal_heading{
    color:#616f7d ;
    font-size: 16px;
    font-weight: 500;
}
  #main-nav > li.current-menu-item > a:after, #main-nav > li:hover > a:after {
    width: 0px; 
}
.showme {
    display: none;
    position: relative;
    width:200px;
  }
  
  .showhim:hover .showme {
    display:block !important;
    position: absolute;
    left: 0;
    cursor: pointer;
  }

  .showme-update {
    display: none;
    position: relative;
    width:200px;
  }
  
  .showhim-update:hover .showme-update {
    display:block !important;
    position: absolute;
    left: 0;
    cursor: pointer;
  }
  .dropdown-content{
      background: #fff;
      padding:0px;
      list-style:none;
  }
  
  .drop-list{
    color:#333 !important;
    line-height:30px !important;
    font-size: 17px;
    padding: 5px;
}
/* #main-nav li a:focus{
    font-weight: bold;
    color: #3B2313;
    
} */

/* tab css start  */
ul.tabs{
    margin: 0px;
    padding: 0px;
    list-style: none;
}
ul.tabs li{
    background: none;
    color: #222;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;
}

ul.tabs li.current{
    
    color: #222;
}

.tab-content{
    display: none;
    
    padding: 15px;
}

.tab-content.current{
    display: inherit;
}
/* tab css end  */

.sc-featured-box {
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 0px 2px rgb(0 0 0 / 2%);
    /* border: 1px solid; */
    margin: auto;
    min-height: 200px;
}

.my_card{
    padding: 10px;
    height: 400px;
    overflow: scroll;
}

#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4CAF50;
    color: white;
  }

  .box{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  }
  .under-box{
    box-shadow: 0 .5rem 1rem rgba(214, 207, 207, 0.15)!important;
  }
 .my_div{
     background-color:fff;
 }
 .new_title{
    font-size: 24px;
    line-height: 27px;
    font-family: "Rancho", sans-serif;
    position: relative;
    color: black;
 }

 .swin-sc-product.products-01.style-04 .products {
    text-align: left;
    padding-bottom: 20px;
}
ul.tabs li.current {
    color: #fff;
    background-color: #222;
    
}
.swin-btn:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #000;
    display: none;
    top: 50%;
    right: 12px;
    margin-top: -3px;
}

.dish_name{
    font-size: 18px;
    max-width: 406px;
    color: #000;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.MuiTableCell-alignLeft {
    text-align: center !important;
}

.MuiTableSortLabel-root {
    cursor: pointer;
    display: initial;
    align-items: center;
    flex-direction: inherit;
    justify-content: center;
}

/* slider css start  */
.slider_images{
    padding: 0px 0px 0px 0px;
    height: 90% !important;
    
}


/* .slick-list{
    min-height: 700px !important;
} */
.bnr_img{
    height: 90vh;
    width: 100% !important;
    object-fit: cover;
}
.bnr_img_30{
    height: 30vh;
    width: 100% !important;
    object-fit: cover;
    padding: 0px 10px;
}
.slick-next {
    right: 10px !important;
}
.slick-prev {
    left: 10px !important;
}

#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #3B2313;
    color: white;
  }

  .my-custom-scrollbar {
    /* position: relative; */
    height: 300px;
    width: 100%;
    overflow: scroll;
    }
    .table-wrapper-scroll-y {
    display: block;
    }

    table th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 10;
        background: #fff;
    }

    .indicator_icon{
        display: inline-block;
    }
    img[title]:hover::after {
        content: attr(title);
        position: absolute;
        top: -100%;
        left: 0;
      }

 

      .menu_img{
        height: 100%;
    } 
@media screen and (min-width: 1400px) {
    /* .counter_top_img{
        max-height: 265px;
        min-height: 308px;

    } */

  
}

.counter_top_img{
    height: 250px !important;
    min-height: 250px;
}

.counter_top_content{
    height: 100% !important;
} 
.sustain_new{
    color:#f4511e;
    font-size:14px;
    font-weight:500;
    margin:0 0 0 10px;
    line-height:0;

}
.work_sustain{
    display:flex;
    align-items: center;
    justify-content:center;    
}
.sustain_new_para{
    color:#000000;
    font-size:14px;
    text-align:center;
    font-weight:400;
    margin-top: 10px;
}
.box-shadow{
    box-shadow:0 0 10px rgb(0 0 0 / 15%);
    padding:12px;
    background:#fff;
    border-radius: 5px;
    margin-bottom: 20px;
}
